<template>
  <div class="resultpage">
    <!-- 等待结果 -->
    <div class="we-flex-col we-flex-center we-justify-content-center margin50" v-if="waiting">
      <div class="margin20b">
        <van-icon name="checked" color="#07c160" size="130" />
      </div>
      <div class="margin20bott text-align-c">
        <span class="we-weight">扫码成功</span><br /><span class="we-font-size-xl">等待设备响应</span>
      </div>
      <div class="round-wrap">
        <van-count-down :time="time" format="ss" @finish="finish" />
        <!-- <van-circle v-model="currentRate" :rate="99" :speed="10" :text="text" layer-color="#ebedf0" /> -->
      </div>
    </div>
    <div v-else>
      <!-- 扫描成功 -->
      <div class="we-flex-col we-flex-center we-justify-content-center margin50" v-if="success">
        <div class="margin20b">
          <van-icon name="checked" color="#07c160" size="130" />
        </div>
        <div class="margin20bott text-align-c">
         已授权信息成功 <br />请到设备端继续操作
        </div>
        <div>
        </div>
        <!-- -->
      </div>
      <!-- 扫描失败 -->
      <div class="we-flex-col we-flex-center we-justify-content-center margin50" v-else>
        <div class="margin20b">
          <van-icon name="warning" color="#ff7565" size="130" />
        </div>
        <div class="margin20bott text-align-c">
          未能与设备建立连接<br />请重新扫描设备上的二维码
        </div>
        <van-button type="default" round @click="$router.push({ name: 'scandevice' })" class="smallbox">进入扫一扫</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Form, NavBar, Circle, Icon, CountDown } from "vant";
// import { login, authoritys } from "@api/auth";
import { getReidsCustomer, getToken, setReidsCustomer } from "@api/system";
// import storage from "../util/storage";
import qs from "qs";
export default {
  name: "scanresult",
  data() {
    return {
      username: "",
      password: "",
      currentRate: 0,
      waiting: true,
      success: false,
      deviceId: "",
      fromtype: "",
      time: 21000,
      runInterval: "",
    };
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + "%";
    },
  },
  components: {
    [Form.name]: Form,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Circle.name]: Circle,
    [Icon.name]: Icon,
    [CountDown.name]: CountDown,
  },

  methods: {
    ajax_checkCustomer() {
     
      let deviceInfo = window.localStorage.getItem("redirect").split("&")[0]; 
      let deviceId = deviceInfo.split("=")[1] 

      let userinfo = window.localStorage.getItem("userinfo");
      let wechatId = JSON.parse(userinfo).openid;
      // let wechatId = 'ovio-6BqsqO2ciFNDiRrmdkjuPp4'
      // let deviceId ='746412425254797312';
      setReidsCustomer({ deviceId: deviceId, wechatId: wechatId}).then(
        (res) => {
          if (res.respCode == 200) {
            this.waiting = true;
            this.runInterval = setInterval(this.ajax_getReidsCustomer, 2000);
            this.ajax_getReidsCustomer();
          } else if (res.respCode == 403) {
            this.$toast("请先注册");
            window.location.href = `http://${window.location.host}/#/register/`;
          } else {
            this.$toast(res.respMsg);
          }
        }
      );
    },
    ajax_getReidsCustomer() {
      let deviceInfo = window.localStorage.getItem("redirect").split("&")[0]; 
      let deviceId = deviceInfo.split("=")[1] 
      let userinfo = window.localStorage.getItem("userinfo");
      let wechatId = JSON.parse(userinfo).openid;
      // let deviceId ='746412425254797312';
      // let wechatId = 'ovio-6BqsqO2ciFNDiRrmdkjuPp4'
      getReidsCustomer({ deviceId: deviceId, wechatId: wechatId }).then(
        (res) => {
          if (res.respCode == 200) {
            
            if(res.result.id !==null){
            clearInterval(this.runInterval);
            this.waiting = false;
            this.success = true;
            window.localStorage.removeItem("deviceId");
            }
          }
          // else {
          //   this.$toast(res.respMsg);
          //   this.waiting = false;
          //   this.success = false;
          //   window.localStorage.removeItem("deviceId");
          // }
        }
      );
    },
    //倒计时结束
    finish() {
      this.success = false;
      clearInterval(this.runInterval);
      this.waiting = false;
      window.localStorage.removeItem("deviceId");
    },
    //进入公众号
    closePage() {
      WeixinJSBridge.call("closeWindow");
    },
    // getCode() {
    //   var url = location.search;
    //   var code = "";
    //   var state = "";
    //   if (url.indexOf("?") != -1) {
    //     url = url.substr(1);
    //   }
    //   let params = this.$qs.parse(url);
    //   if (typeof params.code !== "undefined") code = params.code;
    //   return code;
    // },
    getdeviceId() {
      // var url = location.search;
      var url = window.localStorage.getItem("redirect");
      var deviceId = "";
      if (url.indexOf("?") != -1) {
        // url = url.substr(1) ;
        url = url.split("?")[1];
      }
      let params = this.$qs.parse(url);
      if (typeof params.deviceId !== "undefined") deviceId = params.deviceId;
      window.localStorage.setItem("deviceId", deviceId);
      return deviceId;
    },
  },
  mounted() {
    this.ajax_checkCustomer();
    this.fromtype = window.localStorage.getItem("from") == 1 ? "1" : "";
    window.localStorage.removeItem("from");
  },
};
</script>
<style lang="less">
.resultpage {
  padding: 15px;
  height: 100%;
}
.margin20bott {
  margin: 20px 0;
  font-size: 18px;
  line-height: 30px;
}
.margin50 {
  margin-top: 20%;
}
.smallbox {
  box-shadow: 0px 5px 21px 0px rgba(6, 0, 1, 0.1);
  padding: 0 50px;
  margin-top: 30px;
  font-size: 16px;
}
.round-wrap {
  margin: 0 15px 20px;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 5px 0px rgba(6, 0, 1, 0.2);
  border-radius: 50%;
  .van-count-down {
    font-size: 32px;
    font-weight: bold;
  }
  /* overflow: hidden; */
}
</style>